@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

:root {
  --primary-400: #ff7aa3;
  --primary-500: #ff578c;
  --primary-600: #d54b7b;
  --gray-50: #ededed;
  --gray-300: #80809c;
  --gray-400: #575775;
  --gray-500: #2c2b4f;
  --gray-600: #15142f;
  --gray-700: #0e0f2a;
  --gray-800: #0b0c29;
}

html,
body,
#root {
  height: 100%;
  min-height: fit-content;
  background: linear-gradient(180deg, var(--gray-700) 0%, var(--gray-800) 100%);
  -ms-overflow-style: none;
  scrollbar-width: auto;

  @media (max-width: 768px) {
    font-size: clamp(0.75rem, 2vw, 1rem);
  }

  @media (max-width: 425px) {
    font-size: clamp(0.75rem, 1.5vw, 1rem);
  }
}

html,
body {
  scrollbar-color: var(--gray-500) var(--gray-800);
}

#root {
  white-space: pre-line;
  scrollbar-color: var(--gray-500) transparent;
}

.react-datepicker-popper {
  z-index: 50;

  svg {
    display: none;
  }

  .react-datepicker {
    font-family: 'Open Sans', sans-serif !important;
    border: 1px solid var(--gray-500);
    background: rgba(9, 10, 39, 0.3);
    backdrop-filter: blur(6px);
    color: var(--gray-50);

    h2,
    div,
    span {
      color: var(--gray-50);
    }
  }

  .react-datepicker__header {
    background: transparent;
    border-bottom: 1px dotted var(--gray-500);
  }

  .react-datepicker__day-names {
    font-weight: bolder;
    color: var(--gray-50);
    padding: 2px 0;
  }

  .react-datepicker__month {
    margin: 0;
    padding: 8px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    margin: 4px;
  }

  .react-datepicker__month,
  .react-datepicker__week,
  .react-datepicker__day {
    color: var(--gray-50);
  }

  .react-datepicker__month {
    &:hover {
      background: transparent !important;
    }
  }

  .react-datepicker__day {
    color: var(--gray-50);

    &:hover {
      background: var(--primary-500);
    }
  }

  .react-datepicker__day--keyboard-selected {
    color: var(--gray-50);
    background: transparent;

    &:hover {
      background: var(--primary-500);
    }
  }

  .react-datepicker__day--in-range {
    background: var(--primary-500);
    color: var(--gray-50);

    &:hover {
      background: var(--primary-600);
    }
  }

  .react-datepicker__day--disabled {
    color: var(--gray-300) !important;
    cursor: not-allowed;

    &:hover {
      background: transparent;
    }
  }
}

::-webkit-scrollbar {
  width: 12px;
  background-color: var(--gray-800);
}

::-webkit-scrollbar-thumb {
  border-radius: 100%;
  background-color: var(--gray-500);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-400);
}

.leaflet-tooltip::before {
  width: 0 !important;
  height: 0 !important;
  border: 0;
}

.marker-cluster-small {
  background-color: var(--primary-500) !important;
}

.marker-cluster-small div {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--primary-600) !important;
  color: var(--gray-50) !important;
}

.leaflet-container {
  background: var(--gray-700);
  outline: 0;
  height: 100%;
}
